import Router from "next/router";
import { WorkOrderType } from "./graphql";

export default {
  dashboard: "/",
  onboarding: "/onboarding",
  stats: {
    tenants: "/stats/tenants",
  },
  tenants: {
    index: "/tenants",
    details: (id: string) => `/tenants/${id}`,
    settings: {
      basic: {
        index: "/settings/company",
        edit: "/settings/company/edit",
      },
      accounting: "/settings/accounting",
      hr: "/settings/hr",
      inventory: "/settings/inventory",
      testMode: "/settings/test-mode",
      billing: "/settings/billing",
    },
  },
  users: {
    index: "/users",
    details: (id: string) => `/users/${id}`,
    login: "/user/login",
  },
  user: {
    recoverPassword: "/user/recover-password",
    resetPassword: "/user/reset-password",
    login: "/user/login",
    signUp: "/user/welcome",
  },
  roles: {
    index: "/roles",
    new: "/roles/new",
    details: (id: string) => `/roles/${id}`,
    edit: (id: string) => `/roles/${id}/edit`,
  },
  units: {
    index: "/units",
    details: (id: string) => `/units/${id}`,
  },
  localities: {
    index: "/localities",
    map: "/localities/map",
    new: "/localities/new",
    details: (id: string) => `/localities/${id}`,
    edit: (id: string) => `/localities/${id}/edit`,
  },
  imports: {
    index: "/imports",
    details: (id: string) => `/imports/${id}`,
  },
  importLogs: {
    index: "/import-logs",
    details: (id: string) => `/import-logs/${id}`,
  },
  positions: {
    index: "/positions",
    details: (id: string) => `/positions/${id}`,
  },
  leaveTypes: {
    index: "/leave-types",
    details: (id: string) => `/leave-types/${id}`,
  },
  employees: {
    index: "/employees",
    new: "/employees/new",
    details: (id: string) => `/employees/${id}`,
    edit: (id: string) => `/employees/${id}/edit`,
  },
  employeeGroups: {
    index: "/employee-groups",
    new: "/employee-groups/new",
    details: (id: string) => `/employee-groups/${id}`,
    edit: (id: string) => `/employee-groups/${id}/edit`,
  },
  machineKinds: {
    index: "/machine-types",
    details: (id: string) => `/machine-types/${id}`,
  },
  machines: {
    index: "/machines",
    new: "/machines/new",
    details: (id: string) => `/machines/${id}`,
    edit: (id: string) => `/machines/${id}/edit`,
  },
  brands: {
    index: "/brands",
    details: (id: string) => `/brands/${id}`,
  },
  infrastructures: {
    details: (id: string) => `/infrastructures/${id}`,
  },
  processingPlants: {
    index: "/infrastructures/processing-plants",
    details: (id: string) => `/infrastructures/processing-plants/${id}`,
  },
  warehouses: {
    index: "/infrastructures/warehouses",
    details: (id: string) => `/infrastructures/warehouses/${id}`,
  },
  grids: {
    index: "/infrastructures/grids",
    details: (id: string) => `/infrastructures/grids/${id}`,
  },
  buildings: {
    index: "/infrastructures/structures",
    details: (id: string) => `/infrastructures/structures/${id}`,
  },
  paymentMethods: {
    index: "/payment-methods",
    details: (id: string) => `/payment-methods/${id}`,
  },
  counterparties: {
    index: "/counterparties",
    new: "/counterparties/new",
    details: (id: string) => `/counterparties/${id}`,
    edit: (id: string) => `/counterparties/${id}/edit`,
  },
  itemCategories: {
    index: "/item-categories",
    details: (id: string) => `/item-categories/${id}`,
  },
  items: {
    index: "/items",
    new: "/items/new",
    catalog: "/items/catalog",
    details: (id: string) => `/items/${id}`,
    edit: (id: string) => `/items/${id}/edit`,
  },
  inventory: {
    itemCost: {
      index: "/inventory/item-cost",
      new: "/inventory/item-cost/new",
      details: (id: string) => `/inventory/item-cost/${id}`,
      edit: (id: string) => `/inventory/item-cost/${id}/edit`,
    },
    recipes: {
      index: "/inventory/recipes",
      new: "/inventory/recipes/new",
      details: (id: string) => `/inventory/recipes/${id}`,
      edit: (id: string) => `/inventory/recipes/${id}/edit`,
    },
    adjustments: {
      index: "/inventory/adjustments",
      new: "/inventory/adjustments/new",
      details: (id: string) => `/inventory/adjustments/${id}`,
      edit: (id: string) => `/inventory/adjustments/${id}/edit`,
    },
    adjustmentTypes: {
      index: "/inventory/adjustment-types",
      new: "/inventory/adjustment-types/new",
      details: (id: string) => `/inventory/adjustment-types/${id}`,
      edit: (id: string) => `/inventory/adjustment-types/${id}/edit`,
    },
    ingredients: {
      index: "/inventory/active-ingredients",
      details: (id: string) => `/inventory/active-ingredients/${id}`,
    },
    transfers: {
      index: "/inventory/transfers",
      new: "/inventory/transfers/new",
      details: (id: string) => `/inventory/transfers/${id}`,
      edit: (id: string) => `/inventory/transfers/${id}/edit`,
    },
    purchases: {
      index: "/inventory/purchases",
      new: "/inventory/purchases/new",
      details: (id: string) => `/inventory/purchases/${id}`,
      edit: (id: string) => `/inventory/purchases/${id}/edit`,
    },
    sales: {
      index: "/inventory/sales",
      new: "/inventory/sales/new",
      details: (id: string) => `/inventory/sales/${id}`,
      edit: (id: string) => `/inventory/sales/${id}/edit`,
    },
  },
  agro: {
    diseases: {
      index: "/agro/diseases",
      details: (id: string) => `/agro/diseases/${id}`,
    },
    crops: {
      index: "/agro/crops",
      details: (id: string) => `/agro/crops/${id}`,
    },
    cropVarieties: {
      index: "/agro/crop-varieties",
      details: (id: string) => `/agro/crop-varieties/${id}`,
    },
    cropCycles: {
      index: "/agro/crop-cycles",
      details: (id: string) => `/agro/crop-cycles/${id}`,
    },
    cropFields: {
      index: "/agro/crop-fields",
      new: "/agro/crop-fields/new",
      details: (id: string) => `/agro/crop-fields/${id}`,
      edit: (id: string) => `/agro/crop-fields/${id}/edit`,
    },
    cropStages: {
      index: "/agro/crop-stages",
      details: (id: string) => `/agro/crop-stages/${id}`,
    },
    activityCategories: {
      index: "/agro/activity-categories",
      details: (id: string) => `/agro/activity-categories/${id}`,
    },
    activities: {
      index: "/agro/activities",
      new: "/agro/activities/new",
      catalog: "/agro/activities/catalog",
      details: (id: string) => `/agro/activities/${id}`,
      edit: (id: string) => `/agro/activities/${id}/edit`,
    },
    workOrders: {
      index: "/agro/work-orders",
      new: "/agro/work-orders/new",
      newType: (...types: WorkOrderType[]) =>
        `/agro/work-orders/new?${types.map((t) => "type=" + t).join("&")}`,
      details: (id: string) => `/agro/work-orders/${id}`,
      indexWithFilters: ({
        status,
        inventoryStatus,
      }: {
        status?: string;
        inventoryStatus?: string;
      }) =>
        formatRouteFilter("/agro/work-orders", {
          status: status && [status],
          inventoryStatus: inventoryStatus && [inventoryStatus],
        }),
    },
    plannings: {
      index: "/agro/plannings",
      new: "/agro/plannings/new",
      details: (id: string) => `/agro/plannings/${id}`,
      edit: (id: string) => `/agro/plannings/${id}/edit`,
      start: (id: string) => `/agro/plannings/${id}/start`,
    },
    inventoryRequests: {
      index: "/agro/inventory-requests",
      details: (id: string) => `/agro/inventory-requests/${id}`,
    },
    harvestEstimates: {
      index: "/agro/harvest-estimates",
      new: "/agro/harvest-estimates/new",
      details: (id: string) => `/agro/harvest-estimates/${id}`,
      edit: (id: string) => `/agro/harvest-estimates/${id}/edit`,
    },
    weatherMeasurements: {
      index: "/agro/weather",
      new: "/agro/weather/new",
      edit: (id: string) => `/agro/weather/${id}/edit`,
    },
    metrics: {
      index: "/agro/metrics",
      details: (id: string) => `/agro/metrics/${id}`,
    },
    scoutingNotes: {
      index: "/agro/scouting-notes",
      details: (id: string) => `/agro/scouting-notes/${id}`,
    },
  },
  finance: {
    accounts: {
      index: "/finance/accounts",
      details: (id: string) => `/finance/accounts/${id}`,
    },
    costCenters: {
      index: "/finance/cost-centers",
      details: (id: string) => `/finance/cost-centers/${id}`,
    },
    journalEntries: {
      index: "/finance/journal-entries",
      details: (id: string) => `/finance/journal-entries/${id}`,
      new: "/finance/journal-entries/new",
      edit: (id: string) => `/finance/journal-entries/${id}/edit`,
    },
    expenseOrders: {
      index: "/finance/expense-orders",
      details: (id: string) => `/finance/expense-orders/${id}`,
      new: "/finance/expense-orders/new",
      edit: (id: string) => `/finance/expense-orders/${id}/edit`,
    },
    invoices: {
      index: "/finance/invoices",
      details: (id: string) => `/finance/invoices/${id}`,
    },
    payments: {
      details: (id: string) => `/finance/payments/${id}`,
    },
    tokens: {
      index: "/finance/tokens",
    },
    fiscalYears: {
      index: "/finance/fiscal-years",
      details: (id: string) => `/finance/fiscal-years/${id}`,
    },
    expenseCategories: {
      index: "/finance/expense-categories",
      details: (id: string) => `/finance/expense-categories/${id}`,
    },
    expenseItems: {
      index: "/finance/expense-items",
      details: (id: string) => `/finance/expense-items/${id}`,
    },
    taxComponents: {
      index: "/finance/tax-components",
      details: (id: string) => `/finance/tax-components/${id}`,
    },
    taxPlans: {
      index: "/finance/tax-plans",
      details: (id: string) => `/finance/tax-plans/${id}`,
      new: "/finance/tax-plans/new",
      edit: (id: string) => `/finance/tax-plans/${id}/edit`,
    },
  },
  reports: {
    index: "/reports",
    stock: "/reports/stock",
    inventoryEntries: "/reports/inventory-entries",
    inventoryMovements: ({
      documentType,
      mode,
      variantId,
      localityId,
    }: {
      documentType?: string;
      mode?: string;
      variantId?: string;
      localityId?: string[];
    } = {}) =>
      formatRouteFilter("/reports/inventory-movements", {
        documentType,
        mode,
        variantId,
        localityId,
      }),
    generalLedger: "/reports/general-ledger",
    trialBalance: "/reports/trial-balance",
    balanceSheet: "/reports/balance-sheet",
    ledger: (accountId?: string, date?: any) =>
      formatRouteFilter("/reports/ledger", {
        accountId: accountId ? [accountId] : undefined,
        documentDate: date,
      }),
    journal: "/reports/journal",
    activities: "/reports/activities",
    cropFields: "/reports/crop-fields",
    token: "/reports/token",
    harvest: "/reports/harvest",
    harvestDaily: "/reports/harvest-daily",
    prePayroll: "/reports/pre-payroll",
    workOrderDaily: "/reports/work-orders-daily",
    expense: "/reports/expense",
    inputCostCenter: "/reports/cost-center-inputs",
    incomeStatement: "/reports/income-statement",
    machines: "/reports/machines",
    accountsPayable: "/reports/accounts-payable",
    accountsReceivable: "/reports/accounts-receivable",
  },
  hr: {
    holidays: {
      index: "/hr/holidays",
      new: "/hr/holidays/new",
      edit: (id: string) => `/hr/holidays/${id}/edit`,
      clone: (id: string) => `/hr/holidays/new?source=${id}`,
    },
    leaveAssignments: {
      index: "/hr/leave-assignments",
      new: "/hr/leave-assignments/new",
      details: (id: string) => `/hr/leave-assignments/${id}`,
    },
    salaryComponents: {
      index: "/hr/salary-components",
      details: (id: string) => `/hr/salary-components/${id}`,
    },
    salaryStructures: {
      index: "/hr/salary-structures",
      catalog: "/hr/salary-structures/catalog",
      details: (id: string) => `/hr/salary-structures/${id}`,
      new: "/hr/salary-structures/new",
      edit: (id: string) => `/hr/salary-structures/${id}/edit`,
    },
    payrollEntries: {
      index: "/hr/payroll-entries",
      details: (id: string) => `/hr/payroll-entries/${id}`,
      new: "/hr/payroll-entries/new",
      edit: (id: string) => `/hr/payroll-entries/${id}/edit`,
    },
  },
  profile: {
    basic: {
      index: "/profile/basic",
      edit: "/profile/basic/edit",
    },
  },
};

export function routerPush(routeId: string, route?: (id: string) => string) {
  if (route) {
    Router.push(route("[id]"), route(routeId));
  } else {
    Router.push(routeId);
  }
}

export function routerReload() {
  Router.reload();
}

export function resetQueryParams() {
  Router.replace(window.location.pathname, undefined);
}

function encodeParam(value: any) {
  return encodeURIComponent(JSON.stringify(value));
}

export function formatRouteFilter(route: string, filter: any) {
  return `${route}?filter=${encodeParam(filter)}`;
}
